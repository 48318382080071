.amcharts-amexport-item {
  /* padding: 2px !important; */
  border-radius: 8px !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  /* line-height: 1.67 !important; */
  font-stretch: normal !important;
  letter-spacing: normal !important;
  background-color: #fff !important;
  background-image: url("../../../../components/assets/img/Vector.svg") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  border: 1px solid #dde5ec !important;
  color: #08001d !important;
  top: 0px !important;
  height: 30px !important;
  opacity: 1 !important;
  /* box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15) !important; */
}
.amcharts-amexport-icon {
  opacity: 0;
}
.amcharts-amexport-item:hover {
  background-image: url("../../../../components/assets/img/Vector.svg") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
}

/* .amcharts-amexport-top .amcharts-amexport-item > .amcharts-amexport-menu {
    color: #08001D !important;
  } */
.amcharts-amexport-top .amcharts-amexport-item > a {
  color: #08001d !important;
}

.amcharts-amexport-menu-level-0.amcharts-amexport-top {
  top: 16.25px !important;
  bottom: auto;
}

.amcharts-amexport-menu-level-0.amcharts-amexport-right {
  left: auto;
  right: 15px !important;
}
