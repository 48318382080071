body {
  margin: 0;
  background-color: #f0f4fa;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

#root {
  height: 100vh;
}

.height100 {
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .overHide {
    overflow: hidden;
  }
}

.button_bg {
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #e4eaf5;
  background-color: #ffffff;
}

.button_bg-primary {
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(27, 97, 223, 0.1);
  background-image: linear-gradient(to top, #005aff, #1b61df);
}

.defaultName {
  color: red;
  background-color: red;
}

.canvasjs-chart-tooltip > div > span:nth-child(8) {
  color: #000 !important;
}

.bold-title {
  font-weight: bolder;
  color: #21282d;
  font-size: 16px;
}

.sites-button:hover {
  background-color: #005aff;
  /* Green */
  color: white;
}

.nav-button {
  border-radius: 6px;
  /* border: solid 0.5px #dbdfe7; */
  background-color: #ffffff;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #7e909d;
}

input {
  position: relative;
  z-index: 2;
  outline: none;
  float: left;
  width: 100px;
  margin-bottom: 0;
  display: table-cell;
  /* padding: 6px 8px; */
  border: 1px solid white;

  border-left: 0px;
  margin: 0;
}

.prefix .input-group-addon {
  border-right: 0px;

  /*     border-radius: 4px 0px 0px 4px; */
}

.dashboard-card {
  min-width: 220px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}

.dashboard-card-icon {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 60px;
  padding: 0.5em 0.6em;
}

.dashboard-card .text {
  height: 20px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #7e909d;
}
