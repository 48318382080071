.img-logo {
  width: 150px;
  margin-top: 247px;
  margin-left: -70px;
}

.mail,
.pass,
.remember {
  color: gray;
  height: "44px";
  width: "400px";
}

.outerDiv {
  position: fixed;
  background: #fff;
}
.loginText {
  font-size: 24px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: #21282d !important;
  margin-top: 50px !important;
}
.forgotPass {
  font-size: 16px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.67 !important;
  letter-spacing: normal !important;
  color: #5b4dd3 !important;
  text-decoration: underline;
}
