$purple-500: #5b48fb;

#container {
  width: 70px;
  height: 35px;
  overflow: hidden;
  position: relative;
  // top: calc(50% - 17px);
  // left: calc(50% - 35px);
}

#loader {
  width: 70px;
  height: 70px;
  border-style: solid;
  border-top-color: $purple-500;
  border-right-color: $purple-500;
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotate 2.8s ease-in-out infinite;
  transform: rotate(-200deg);
}

@keyframes rotate {
  0% {
    border-width: 16px;
  }
  25% {
    border-width: 6px;
  }
  50% {
    transform: rotate(115deg);
    border-width: 16px;
  }
  75% {
    border-width: 6px;
  }
  100% {
    border-width: 16px;
  }
}
