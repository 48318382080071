@font-face {
  font-family: Montserrat;
  src: url("./assets/fonts/Inter-Light.otf");
}

@font-face {
  font-family: airProbeRegular;
  src: url("./assets/fonts/Inter-Regular.otf");
}

@font-face {
  font-family: airProbeBold;
  src: url("./assets/fonts/Inter-ExtraBold.otf");
}

* {
  font-family: Montserrat;
  font-size: 12px;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

a {
  text-decoration: none !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
a {
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  font-size: 12px;
}

a:hover {
  text-decoration: none !important;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.button-primary {
  font-size: "13px";
  border-radius: "8px";
  border-color: "#e1e5e8";
}

.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
}

.square {
  height: 10px;
  width: 10px;
  border-radius: 20%;
  display: inline-block;
}

.ul {
  list-style: none;
}

.ul .li::before {
  /* \2022 is the CSS Code/unicode for a disc */
  content: "\2022";
  color: #08cc85;
  display: inline-block;
  width: 0.7em;
  margin-top: -0.3em;
  margin-left: -2.1em;
  font-weight: bold;
  font-size: 1.3rem;
}

.active {
  background-color: gold;
  color: Black;
  font-weight: bold;
}

hr {
  border-top: 1px solid #f4f2f2;
}

/* CONFIGURATION PAGE */
.settings__title {
  /*padding: 24px 20px !important;*/
  font-size: 22px;
  margin: 0 !important;
  padding: 0 !important;
  /*margin-top: 0 !important;*/
}

/*.settings__table {*/
/*    margin-bottom: 20px;*/
/*}*/

.settings__navbar {
  padding: 24px 20px !important;
}

.settings__card {
  border-radius: 5px;
}

.settings__background {
  background-color: rgb(239, 244, 251);
  padding: 20px;
}

.settings__card .ant-tabs-nav .ant-tabs-tab-active {
  color: #005aff !important;
  font-weight: 500 !important;
}

.settings__card .ant-tabs-nav .ant-tabs-tab:hover {
  color: #005aff !important;
  font-weight: 500 !important;
}

.settings__card .ant-tabs-nav-wrap {
  padding: 0 20px !important;
}

.settings__card .ant-tabs-bar {
  margin: 0 0 20px 0;
}

.settings__card .defect__table__1 {
  padding-left: 20px;
  padding-right: 30px;
  margin-bottom: 30px;
}

.settings__card th.ant-table-row-cell-break-word {
  background-color: #f4f7fc;
}

.settings__card th.ant-table-row-cell-break-word,
.settings__card td.ant-table-row-cell-break-word {
  padding: 12px 15px !important;
}

.settings__card .defect__table__1 .table-striped-rows table {
  border-color: #e4eaf5;
}

.settings__card .defect__table__right__section {
  font-size: 14px;
  border-radius: 5px;
  border: solid 1px #e4eaf5;
  background-color: #f4f7fc;
  padding: 15px 20px 15px 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #536471;
}

.settings__card .defect__table__right__section__parent {
  padding-right: 20px;
  padding-left: 0;
}

.settings__card .defect__table__right__section span {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 30px;
  letter-spacing: normal;
  color: #21282d;
}

.settings__card .defect__table__1 .table__input {
  margin: 0 10px;
  width: 275px;
}

.settings__card .defect__table__1 .table__save {
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(27, 97, 223, 0.1);
  background-image: linear-gradient(to top, #1165ff, #1b61df);
  color: white;
  margin: 0;
  border: 0;
  padding: 5px 10px;
  line-height: 0;
}

.settings__card .defect__table__1 .right__buttons {
  display: flex;
  align-items: center;
}

.bold {
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.success {
  color: #08cc85;
}
.danger {
  color: #f0564c;
}
.user-item {
  word-break: break-word;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  font-size: 12px;
  color: #536471;
}
.font-size-12 {
  font-size: 12px;
}

.user-drawer-menu {
  color: #7e909d;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-12 {
  font-size: 12px;
}

.side-bar-row {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.inspAlign ul {
  list-style: none;
}

.inspAlign li {
  text-align: left;
}

.inspAlign ul li:before {
  content: "\2022";
  color: #1b61df;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -2em;
  margin-right: 19px;
  font-size: 20px;
  line-height: 1;
}
