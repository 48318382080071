@media (min-width: 600px) {
  .col-md-6 {
    flex: 5px;
    height: 300px;
  }
}
@media (max-width: 600px) {
  .ml-3 {
    visibility: hidden;
  }
}
