/* Hide scrollbar for Chrome, Safari and Opera */
.swiperScroll::-webkit-scrollbar,
.GRTableContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.swiperScroll,
.GRTableContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

input::-webkit-calendar-picker-indicator {
  display: none;
}
.optionContainer li.option:hover,
.optionContainer .highlight {
  background-color: #5B4DD3;
  color: #fff;
}
