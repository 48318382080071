.img-logo {
  max-width: 150px;
  margin-top: 247px;
  margin-left: -70px;
}
.Arrowback {
  display: flex;
  margin-top: -9%;
  padding-bottom: 8%;
}
.Back {
  cursor: pointer;
}

.Back-page {
  font-size: 14px;
  font-weight: bold;
}
.comment {
  margin: 35px 0;
  font-size: 13px;
  font-weight: bold;
}
.form {
  margin-top: 15%;
}
.label1 {
  margin-bottom: -2%;
}
.heading {
  max-width: 400px;
}
.root1 {
  flex-grow: 1;
  padding: 64;
  margin-left: 5%;
  margin-top: 4%;
  float: left;
}
.rowC {
  height: 100%;
}

@media (max-width: 1250px) {
  .root1 {
    padding-bottom: 2%;
    margin-top: 12%;
  }
}

@media (max-width: 600px) {
  .root1 {
    padding: 10px;
    margin-top: 50px !important;
  }
  .overHide {
    overflow: hidden;
  }
}